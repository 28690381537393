import * as React from "react";
import { Header, Grid, Container, Segment } from "semantic-ui-react";

const Explainer = () => {
  return (
    <Segment vertical>
      <Container textAlign="center">
        <Header as="h1">
          <Header.Content>Connecting tech workers to the next economy</Header.Content>
        </Header>
        <Grid stackable divided columns={3} style={{marginTop: 20}}>
          <Grid.Row>
            <Grid.Column>
              <Header>
                Imagine a different future
              </Header>
              <p>
                We aren't going to program our way out of capitalism, but by building sustainable alternatives
                to legacy enterprises we can show that other worlds are possible. When capitalism fails, the world
                will look to us for new ideas.
              </p>
            </Grid.Column>
            <Grid.Column>
              <Header>
                Make fewer compromises
              </Header>
              <p>
                As workers in private corporations, we don't have control over how our labor is used.
                By organizing into cooperatives, we can take ultimate responsibility for our impact on the world.
              </p>
            </Grid.Column>
            <Grid.Column>
              <Header>
                Make change by creating
              </Header>
              <p>
                Not everyone can be a technologist, but you are. Your unique skills are needed to build a better world.
                The best way for you to make an impact is to <em>build things</em>.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default Explainer;
