import * as React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import SectionTitle from "../components/SectionTitle";
import { get } from "lodash";
import { Button, Item, Grid, Card, List, Container, Feed, Segment, Label, Divider, Header, Form } from "semantic-ui-react";
import { MarkdownRemarkConnection, ImageSharp } from "../graphql-types";
import {withLayout, LayoutProps} from "../components/Layout";
import { MarkdownRemark } from "../graphql-types";
import JobsList from "../components/JobsList/JobsList";
import Explainer from "../components/Explainer/Explainer";
import ListingPagination from "../components/ListingPagination/ListingPagination";
import ComingSoonDialog from "../components/ComingSoonDialog";

interface IndexPageProps extends LayoutProps {
  data: {
    jobs: MarkdownRemarkConnection;
  };
  pageContext: {
    tag?: string; // only set into `templates/tags-pages.tsx`
  };
}

const IndexPage = (props: IndexPageProps) => {
  const jobs = props.data.jobs.edges;
  const { pathname } = props.location;
  const pageCount = Math.ceil(props.data.jobs.totalCount / 10);

  const Jobs = (
    <Container style={{marginBottom: "20px", marginTop: "30px", position: "relative"}}>
      <ComingSoonDialog showComingSoon={true}>
        <JobsList
          jobs={jobs.map((jobEdge) => jobEdge.node)}
        />
      </ComingSoonDialog>
    </Container>
  );

  return (
    <Container>
      {/* Content */}
      <Segment vertical>
        <Grid padded style={{ justifyContent: "space-around" }}>

          <Explainer />

          <Grid.Row>
            <div style={{ maxWidth: 600 }}>
              {Jobs}
            </div>
          </Grid.Row>
          <Grid.Row>
            <ListingPagination Link={Link} pathname={pathname} pageCount={pageCount} />
          </Grid.Row>

        </Grid>

      </Segment>
    </Container>
  );
};

export default withLayout(IndexPage);

export const pageQuery = graphql`
query PageIndex {
  jobs: allMarkdownRemark(
    filter: {
      frontmatter: {
        draft: { ne: true }
        placeholder: { eq: true }
      },
      fileAbsolutePath: {regex: "/\/jobs\//"},
      excerpt: {}
    }
    sort:{ order:DESC, fields:[frontmatter___rank]}
    limit: 10
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          organization
          jobTitle
          location
          url
          tags
          image {
            children {
              ... on ImageSharp {
                fixed(width: 120) {
                	src
            			srcSet
                }
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`;
