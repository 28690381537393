import * as React from "react";
import { useState } from "react";
import { Header, Grid, Container, Segment, Button, Divider, Form, Message } from "semantic-ui-react";

interface ComingSoonDialogProps {
  showComingSoon: boolean;
  children: React.ReactNode;
}

const ComingSoonDialog = (props: ComingSoonDialogProps) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event: (React.ChangeEvent | React.FormEvent)) => {
    const target = event.target as HTMLInputElement;
    setEmail(target.value.trim());
  };

  const handleSubmitEmailSignup = (ev: React.FormEvent) => {
    setSubmitted(true);
    ev.preventDefault();
    const submitData = new URLSearchParams();
    submitData.append("email", email);
    const formResult = fetch(
      "https://formspree.io/f/mjvpezzo",
      {
        body: submitData,
        headers: {
          Accept: "application/json",
        },
        method: "POST",
      },
    );
    formResult.then((result: any) => {
      setSuccess(true);
    });
  };

  if (!props.showComingSoon) {
    return props.children;
  }
  return (
    <div style={{
      WebkitMaskImage: "linear-gradient(to top, transparent 5%, black 100%)",
      maskImage: "linear-gradient(to top, transparent 5%, black 100%)",
      paddingTop: "20px",
    }}>
      {props.children}
      <div style={{
        backgroundColor: "#ccccccdd",
        position: "absolute",
        backdropFilter: "blur(3px)",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}>
        <Segment vertical inverted padded style={{
          marginLeft: "30px",
          marginRight: "30px",
          marginTop: "30px",
        }}>
          <Container textAlign="center">
            <Header as="h1" inverted>
              <Header.Content>Coming Soon</Header.Content>
            </Header>
            <p>
              getmeoutof.tech is launching in <em>March&nbsp;of&nbsp;2021</em>.
            </p>
            {/* <Divider inverted /> */}
            <Header as="h3" inverted>
              <Header.Content>Sign up for updates</Header.Content>
            </Header>
            <Form
              onSubmit={handleSubmitEmailSignup}
              success={true}
            >
              <Grid doubling>
                <Grid.Row centered only="computer">
                  <Grid.Column width={8}>
                    <Form.Input onChange={handleChange} fluid inverted placeholder="Email" />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Form.Button fluid inverted>Submit</Form.Button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row only="tablet mobile" centered>
                  <Grid.Column mobile={14}>
                    <Form.Input onChange={handleChange} fluid inverted placeholder="Email" />
                  </Grid.Column>
                  <Grid.Column mobile={8}>
                    <Form.Button disabled={submitted} fluid inverted>Submit</Form.Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

            </Form>
            <Divider/>
            <p>
              <Button inverted primary>
                Submit a job listing
              </Button>
            </p>
          </Container>
        </Segment>
      </div>

    </div>
  );
};

export default ComingSoonDialog;
